import React from 'react';

const TitleAbout = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="bg-slate-50 justify-between items-stretch max-w-[1170px] rounded-xl mt-32 flex w-full gap-5 px-10 py-9 max-md:max-w-full max-md:flex-wrap max-md:px-5">
        <div className="flex flex-col items-stretch max-md:max-w-full">
          <div className="text-slate-500 text-lg leading-5 max-md:max-w-full">About Us</div>
          <div className="text-primary1 text-5xl font-bold leading-[49.92px] mt-6 max-md:max-w-full max-md:text-4xl">Dream Vision Homes</div>
          <div className="text-slate-900 text-lg leading-8 mt-3 max-md:max-w-full">
            Opening Doors to your Dream.
            <br />
            Let’s Make it Reality!
          </div>
        </div>
        <img loading='lazy' src='/img/20years.png' width={1000} height={1000} alt='20years' className='w-fit h-40'/>
        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e00805cf41243818cd452ce49f40f56a49a24268b18ee38237dd5bf5161e1ac?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-[1.38] object-contain object-center w-full overflow-hidden max-w-[221px]" alt="dv" /> */}
      </div>
    </div>
  );
};
export default TitleAbout;
