import React, { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Box, Grid, TextField, IconButton, Container, Snackbar, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


function EggHuntForm() {

  const [formData, setFormData] = useState({
    firstName : '',
    lastName : '',
    email : '',
    phoneNumber : '',
    address : {
      street : '',
      apartmentNo : '',
      city : '',
      state : '',
      zipCode : '',
    },
    totalattendees : '',
    children: [
      { name: '', age: ''},
      { name: '', age: ''},
    ],
    friend : [
      {
        firstName : '', 
        lastName : '',
        email : '',
        phoneNumber : '',   
        address : {
          street : '', 
          apartmentNo : '', 
          city : '', 
          state : '', 
          zipCode : '', 
        },
        totalattendees : '', 
        children: [
          { name: '', age: ''},
          { name: '', age: ''},
        ],
      }
    ]
  })

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbar2, setOpenSnackbar2] = useState(false);


  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleArrayChange = (key, index, field, value) => {
    const newArr = [...formData[key]];
    newArr[index] = { ...newArr[index], [field]: value };
    setFormData((prev) => ({ ...prev, [key]: newArr }));
  };

  const addChild = () => {
    handleArrayChange('children', formData.children.length, '', {
      name: '',
      age: '',
    });
  };

  const addFriend = () => {
    setFormData((prev) => ({
      ...prev,
      friend: [
        ...prev.friend,
        {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: {
            street: '',
            apartmentNo: '',
            city: '',
            state: '',
            zipCode: '',
          },
          totalattendees: '',
          children: [{ name: '', age: '' }],
        },
      ],
    }));
  };
  
  const handleNestedArrayChange = (friendIndex, key, index, field, value) => {
    setFormData((prev) => {
      const newFriends = [...prev.friend];
  
      // Ensure key exists in the friend object
      if (!newFriends[friendIndex][key]) {
        newFriends[friendIndex][key] = [];
      }
  
      newFriends[friendIndex][key][index] = { 
        ...newFriends[friendIndex][key][index], 
        [field]: value 
      };
  
      return { ...prev, friend: newFriends };
    });
  };
  

  const addFriendChild = (friendIndex) => {
    setFormData((prev) => {
      const newFriends = [...prev.friend];
      newFriends[friendIndex].children.push({ name: '', age: '' });
  
      return { ...prev, friend: newFriends };
    });
  };

  const removeFriendChild = (friendIndex, childIndex) => {
    setFormData((prev) => {
      const newFriends = [...prev.friend];
      newFriends[friendIndex].children = newFriends[friendIndex].children.filter((_, i) => i !== childIndex);
  
      return { ...prev, friend: newFriends };
    });
  };

  const handleNestedObjectChange = (friendIndex, key, field, value) => {
    setFormData((prev) => {
      const newFriends = [...prev.friend];
  
      // Ensure the friend exists
      if (!newFriends[friendIndex]) return prev;
  
      // Update the nested object
      newFriends[friendIndex] = {
        ...newFriends[friendIndex],
        [key]: {
          ...newFriends[friendIndex][key],
          [field]: value,
        },
      };
  
      return { ...prev, friend: newFriends };
    });
  };
  
  const removeField = (key, index) => {
    const newArr = formData[key].filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, [key]: newArr }));
    if (formData[key].length > (key === 'children' ? 2 : 1)) {
      setOpenSnackbar2(true);
    } else {
      setOpenSnackbar(true);
    }
  };

  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Message sent successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    console.log("Form Data:", formData);

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    };
    try {
      const response = await axios.post('https://dvhomesfront.vercel.app/api/egghunt',formData)

      // const response = await axios.post('http://localhost:3001/api/egghunt', formData);

      console.log('Form Data Submitted:', response.data);
      console.log(formData)
      showAlert();
      // Clear the form after successful submission
    setFormData({
      firstName : '',
    lastName : '',
    email : '',
    phoneNumber : '',
    address : {
      street : '',
      apartmentNo : '',
      city : '',
      state : '',
      zipCode : '',
    },
    totalattendees : '',
    children: [
      { name: '', age: ''},
      { name: '', age: ''},
    ],
    friend : [
      {
        firstName : '', 
        lastName : '',
        email : '',
        phoneNumber : '',   
        address : {
          street : '', 
          apartmentNo : '', 
          city : '', 
          state : '', 
          zipCode : '', 
        },
        totalattendees : '', 
        children: [
          { name: '', age: ''},
          { name: '', age: ''},
        ],
      }
    ]
    });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container className="mt-20">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box bgcolor="background.paper" p={4} borderRadius={2} boxShadow={3} width="100%" maxWidth="1170px" my={4}>
            <h1
              style={{
                textAlign: 'center',
                fontSize: '2rem',
                fontWeight: 'bold',
              }}
            >
              Dream Vision Easter Egg Hunt 2025 Registration
            </h1>
           
            <form className="w-full mt-6" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <InputField 
                  label="First Name" 
                  id="firstname" 
                  placeholder="First name" 
                  value={formData.firstName} 
                  onChange={(e) => handleInputChange('firstName', e.target.value)} 
                  xs={12} 
                  md={6}  
                  required
                  pattern="[A-Za-z\s]+" 
                  errorMessage="Please enter a valid first name (letters only)."
                />
                <InputField 
                  label="Last Name" 
                  id="lastname" 
                  placeholder="Last name" 
                  value={formData.lastName} 
                  onChange={(e) => handleInputChange('lastName', e.target.value)} 
                  xs={12} 
                  md={6} 
                  required
                  pattern="[A-Za-z\s]+"
                  errorMessage="Please enter a valid last name (letters and spaces only)."

                  />

                <InputField 
                  label="Email Address" 
                  id="email" 
                  placeholder="Email Address" 
                  type="email" 
                  value={formData.email} 
                  onChange={(e) => handleInputChange('email', e.target.value)} 
                  xs={12} 
                  md={6} 
                  required 
                  errorMessage="Please enter a valid email address."
                />
                <InputField 
                  label="Phone Number" 
                  id="number" 
                  placeholder="Enter your mobile number" 
                  value={formData.phoneNumber} 
                  onChange={(e) => handleInputChange('phoneNumber', e.target.value)} 
                  type="tel" 
                  pattern="[0-9]{10}" 
                  xs={12} 
                  md={6} 
                  required 
                  errorMessage="Please enter a 10-digit phone number."
                />

                <Grid item xs={12}>
                   <h3 className='font-semibold text-lg'> Address</h3>
                </Grid>

                <InputField 
                  label="Street Address" 
                  id="street" 
                  placeholder="Enter Street" 
                  value={formData.address.street} 
                  onChange={(e) => handleInputChange('address', { ...formData.address, street: e.target.value })}  
                  xs={12} 
                  md={6} 
                  required 
                />
                <InputField 
                  label="Apartment No" 
                  id="apartmentNo" 
                  placeholder="Enter Apartment No" 
                  value={formData.address.apartmentNo} 
                  onChange={(e) => handleInputChange('address', { ...formData.address, apartmentNo: e.target.value })}  
                  xs={12} 
                  md={6}   
                />
                <InputField 
                  label="City" 
                  id="city" 
                  placeholder="Enter City"
                  value={formData.address.city} 
                  onChange={(e) => handleInputChange('address', { ...formData.address, city: e.target.value })}  
                  xs={12} 
                  md={6} 
                  required 
                  pattern="[A-Za-z\s]+"
                />
                <InputField 
                  label="State" 
                  id="state" 
                  placeholder="Enter State" 
                  value={formData.address.state} 
                  onChange={(e) => handleInputChange('address', { ...formData.address, state: e.target.value })}  
                  xs={12} 
                  md={6} 
                  required 
                   pattern="[A-Za-z\s]+"
                />
                <InputField 
                  label="Zip Code" 
                  id="zipCode" 
                  placeholder="Enter Zip Code" 
                  value={formData.address.zipCode} 
                  onChange={(e) => handleInputChange('address', { ...formData.address, zipCode: e.target.value })}  
                  xs={12} 
                  md={12} 
                  required 
                />

                <InputField 
                  label="Total Number of Attendees (including children)" 
                  id="totalattendees" 
                  placeholder="Total Number of attendees (including children)" 
                  value={formData.totalattendees} 
                  onChange={(e) => handleInputChange('totalattendees', e.target.value)} 
                  xs={12} 
                  md={12} 
                  required 
                  errorMessage="Please enter a valid number."
                  min="1" 
                  type="number" 
                  
                />

                <Grid item xs={12}>
                   <h3 className='font-semibold text-lg'> Children Details</h3>
                </Grid>

                {formData.children.map((children, index)=>(
                <React.Fragment key={index}>
                  <InputField 
                    label={`Child ${index + 1} Name`} 
                    id={`child ${index + 1}`} 
                    placeholder={`Enter ${index + 1} Child name`} 
                    value={children.name} 
                    onChange={(e)=> handleArrayChange('children', index, 'name', e.target.value)}
                    xs={12} 
                    md={6} 
                    pattern="[A-Za-z\s]+" 
                  />
                  <InputField 
                    label={`Child ${index + 1} Age`} 
                    id={`child ${index + 1}`} 
                    placeholder={`Enter ${index + 1} child age`} 
                    value={children.age} 
                    onChange={(e)=> handleArrayChange('children', index, 'age', e.target.value)}
                    xs={11} 
                    md={5} 
                    min="1"  
                    type="number" 
                  />

                  <Grid item xs={1} md={1}>
                    <IconButton onClick={() => removeField('children', index)}  color="black">
                      <DeleteIcon />
                    </IconButton>
                </Grid>
                </React.Fragment>
                ))}
                <AddMoreButton onClick={addChild} label="+ Add Another Child" />

                <Grid item xs={12}>
                   <h3 className='font-semibold text-lg'> Friends Details</h3>
                </Grid>
                
                {formData.friend.map((friend, index)=>(
                  <React.Fragment key={index}>
                    <InputField 
                      label={`Friend ${index + 1} First Name`} 
                      id={`friend ${index + 1} First Name`} 
                      placeholder={`Enter ${index + 1} First Friend Name`} 
                      value={friend.firstName} 
                      onChange={(e) => handleArrayChange('friend', index, 'firstName', e.target.value)} 
                      xs={12} 
                      md={6}  
                      pattern="[A-Za-z\s]+"
                    />
                    <InputField 
                      label={`Friend ${index + 1} Last Name`} 
                      id={`friend ${index + 1} Last Name`} 
                      placeholder={`Enter ${index + 1} friend Last Name`} 
                      value={friend.lastName} 
                      onChange={(e) => handleArrayChange('friend', index, 'lastName', e.target.value)} 
                      xs={12} 
                      md={6}  
                      pattern="[A-Za-z\s]+" 
                    />
                    <InputField 
                      label={`Friend ${index + 1} Email Address`} 
                      id={`Friend ${index + 1} Email id`} 
                      placeholder={`Friend ${index + 1} Email Address`} 
                      type="email" 
                      value={friend.email} 
                      onChange={(e) => handleArrayChange('friend', index, 'email', e.target.value)} 
                      xs={12} 
                      md={6} 
                    />
                    <InputField 
                      label={`Friend ${index + 1} Phone Number`} 
                      id={`Friend ${index + 1} Phone Number`} 
                      placeholder={`Friend ${index + 1} mobile number`} 
                      value={friend.phoneNumber} 
                      onChange={(e) => handleArrayChange('friend', index, 'phoneNumber', e.target.value)} 
                      type="tel" 
                      pattern="[0-9]{10}" 
                      xs={12} 
                      md={6} 
                    />

                    <Grid item xs={12}>
                    <h3 className='font-semibold text-sm'> Address</h3>
                  </Grid>

                  <InputField 
                    label={`Friend ${index + 1} Street Address`} 
                    id={`Friend ${index + 1} street Address`} 
                    placeholder={`Friend ${index + 1} Street Address`} 
                    value={friend.address.street} 
                    onChange={(e) => handleNestedObjectChange(index, 'address', 'street', e.target.value)}  
                    xs={12} 
                    md={6}  
                  />
                  <InputField 
                    label={`Friend ${index + 1} Apartment No`} 
                    id={`Friend ${index + 1} Apartment No`} 
                    placeholder={`Friend ${index + 1} Apartment No`} 
                    value={friend.address.apartmentNo} 
                    onChange={(e) => handleNestedObjectChange(index, 'address', 'apartmentNo', e.target.value)}  
                    xs={12} 
                    md={6} 
                      
                  />
                  <InputField 
                    label={`Friend ${index + 1} City`} 
                    id={`Friend ${index + 1} City`}  
                    placeholder={`Friend ${index + 1} City`}  
                    value={friend.address.city} 
                    onChange={(e) => handleNestedObjectChange(index, 'address', 'city', e.target.value)}  
                    xs={12} 
                    md={6}  
                    pattern="[A-Za-z\s]+" 
                  />
                  <InputField 
                    label={`Friend ${index + 1} State`}  
                    id={`Friend ${index + 1} State`} 
                    placeholder={`Friend ${index + 1} State`} 
                    value={friend.address.state} 
                    onChange={(e) => handleNestedObjectChange(index, 'address', 'state', e.target.value)}  
                    xs={12} 
                    md={6}  
                    pattern="[A-Za-z\s]+" 
                  />
                  <InputField 
                    label={`Friend ${index + 1} Zip Code`} 
                    id={`Friend ${index + 1} Zip Code`} 
                    placeholder={`Friend ${index + 1} Zip Code`} 
                    value={friend.address.zipCode} 
                    onChange={(e) => handleNestedObjectChange(index, 'address', 'zipCode', e.target.value)}  
                    xs={12} 
                    md={12}  
                    type="number" 
                  />
                  <Grid item xs={12}> 
                    <h3 className='font-semibold text-lg'> Children Details</h3>
                  </Grid>
                  {friend.children.map((children, childIndex)=>(
                  <React.Fragment key={childIndex}>
                    <InputField 
                      label={`Child ${childIndex + 1} Name`} 
                      id={`child ${childIndex + 1}`} 
                      placeholder={`Enter ${childIndex + 1} Child name`} 
                      value={children.name} 
                      onChange={(e) => handleNestedArrayChange(index, 'children', childIndex, 'name', e.target.value)}
                      xs={12} 
                      md={6}  
                      pattern="[A-Za-z\s]+" 
                    />
                    <InputField 
                      label={`Child ${childIndex + 1} Age`} 
                      id={`child ${childIndex + 1}`} 
                      placeholder={`Enter ${childIndex + 1} child age`} 
                      value={children.age} 
                      onChange={(e) => handleNestedArrayChange(index, 'children', childIndex, 'age', e.target.value)}
                      xs={11} 
                      md={5}  
                      min="1" 
                      type="number"
                    />
                    <Grid item xs={1} md={1}>
                      <IconButton onClick={() => removeFriendChild(index, childIndex)}  color="black">
                        <DeleteIcon />
                      </IconButton>
                  </Grid>
                  </React.Fragment>
                  ))}
                  <AddMoreButton onClick={() => addFriendChild(index)} label="+ Add Another Child" />
                  <InputField 
                    label={`Friend ${index + 1} Total Number of Attendees (including children)`} 
                    id={`Friend ${index + 1} Total Number of Attendees (including children)`} 
                    placeholder={`Friend ${index + 1} Total Number of Attendees (including children)`} 
                    value={formData.friend[index]?.totalattendees || ''} 
                    onChange={(e) => handleArrayChange('friend', index, 'totalattendees', e.target.value)} 
                    xs={11} 
                    md={11}
                    type="number" 
                    min="1" 
                  />

                  <Grid item xs={1} md={1}>
                      <IconButton onClick={() => removeField('friend', index)}  color="black">
                        <DeleteIcon />
                      </IconButton>
                  </Grid>
                  </React.Fragment>
                ))}
                <AddMoreButton onClick={addFriend} label="+ Add Another Friend" />

              </Grid>
              <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    style={{
                      borderRadius: '45px',
                      padding: '10px 0',
                      marginTop: '1rem',
                    }}
                  >
                    Submit
                  </Button>
                  <Typography variant="body2" align="center" color="textSecondary" className="mt-6">
                    *Disclaimer: All the information provided on this form will be kept confidential and will not be shared with any 3rd parties.
                  </Typography>
                </Grid>
            </form>
          </Box>
        </Box>
      </Container>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message="Cannot remove, minimum fields required" />
      <Snackbar open={openSnackbar2} autoHideDuration={6000} onClose={() => setOpenSnackbar2(false)} message="Item removed" />

    </LocalizationProvider>
  );
}

function DateField({ label, id, xs, md, value, onChange, required }) {
  return (
    <Grid item xs={xs} md={md}>
      <DatePicker label={label} value={value} onChange={onChange} renderInput={(params) => <TextField {...params} fullWidth required={required} />} />
    </Grid>
  );
}

function InputField({ label, id, placeholder, type = 'text', xs, md, value, onChange, required, pattern, errorMessage }) {
  const handleInvalid = (e) => {
    e.target.setCustomValidity(errorMessage);
  };

  const handleInput = (e) => {
    e.target.setCustomValidity('');
  };

  return (
    <Grid item xs={xs} md={md}>
      <TextField 
        label={label} 
        id={id} 
        name={id} 
        placeholder={placeholder} 
        type={type} 
        fullWidth 
        variant="outlined" 
        value={value} 
        onChange={onChange} 
        required={required} 
        inputProps={{ pattern }}
        onInvalid={handleInvalid}
        onInput={handleInput}
      />
    </Grid>
  );
}

function TextAreaField({ label, id, placeholder, xs, value, onChange, required }) {
  return (
    <Grid item xs={xs}>
      <TextField label={label} id={id} name={id} placeholder={placeholder} multiline rows={4} fullWidth variant="outlined" value={value} onChange={onChange} required={required} />
    </Grid>
  );
}

function AddMoreButton({ onClick, label }) {
  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        onClick={onClick}
        style={{
          background: 'linear-gradient(145deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1))',
          backdropFilter: 'blur(10px)',
          borderRadius: '40px',
          color: 'black',
          margin: '2px 0',
          border: '1px solid black',
          transition: 'all 0.3s ease',
          padding: '5px 20px',
          fontSize: '1rem',
        }}
        sx={{
          '&:hover': {
            background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2))',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            transform: 'translateY(-2px)',
          },
        }}
        
      >
        {label}
      </Button>
    </Grid>
  );
}

export default EggHuntForm;