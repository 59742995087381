import React from 'react'
import EggHuntForm from './EggHuntForm'

const EasterEggHunt = () => {
  return (
    <div>
      <EggHuntForm/>
    </div>
  )
}

export default EasterEggHunt