import React, { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Box, Grid, TextField, IconButton, Container, Snackbar, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


function AboutYouForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName : '',
    address: {
        street: '',
        apartmentNo: '',
        city: '',
        state: '',
        zipCode: '',
    },
    email: '',
    phoneNumber: '',    
    birthday: null,
    weddingAnniversary: null,
    favoriterestaurant: '',
    hobby : '',
    spouseName: '',
    spouseemail: '',
    spouseBirthday: null,
    spousesfavoriteRestaurant : '',
    spouseHobby: '',
    children: [{ name: '', birthday: null, restaurant: '', hobby: '' }],
    pets: [{ name: '', species: '' }],
    contacts: [
        { name: '', phone: '' , email: ''},
        { name: '', phone: '', email: ''},
    ],
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbar2, setOpenSnackbar2] = useState(false);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleArrayChange = (key, index, field, value) => {
    const newArr = [...formData[key]];
    newArr[index] = { ...newArr[index], [field]: value };
    setFormData((prev) => ({ ...prev, [key]: newArr }));
  };

  const addChild = () => {
    handleArrayChange('children', formData.children.length, '', {
      name: '',
      birthday: new Date(),
      childfavoriterestaurant : '',
      hobby: '',
    });
  };

  const addPet = () => {
    handleArrayChange('pets', formData.pets.length, '', {
      name: '',
      species: '',
    });
  };

  const removeField = (key, index) => {
    const newArr = formData[key].filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, [key]: newArr }));
    if (formData[key].length > (key === 'contacts' ? 2 : 1)) {
      setOpenSnackbar2(true);
    } else {
      setOpenSnackbar(true);
    }
  };
  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Message sent successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData)

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    };
    
    try {
      // const response = await axios.post('http://localhost:3001/api/allaboutyou', formData);

      const response = await axios.post('https://dvhomesfront.vercel.app/api/allaboutyou',formData)

      console.log('Form Data Submitted:', response.data);
      console.log(formData)
      showAlert();
      // Clear the form after successful submission
    setFormData({
      firstName: '',
      lastName : '',
      address: {
        street: '',
        apartmentNo: '',
        city: '',
        state: '',
        zipCode: '',
      },
    email: '',
    phoneNumber: '',    
    birthday: null,
    weddingAnniversary: null,
    favoriterestaurant: '',
    hobby : '',
    spouseName: '',
    spouseemail: '',
    spouseBirthday: null,
    spousesfavoriteRestaurant : '',
    spouseHobby: '',
    children: [{ name: '', birthday: null, restaurant: '', hobby: '' }],
    pets: [{ name: '', species: '' }],
    contacts: [
        { name: '', phone: '' , email: ''},
        { name: '', phone: '', email: ''},
    ],
    });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container className="mt-20">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box bgcolor="background.paper" p={4} borderRadius={2} boxShadow={3} width="100%" maxWidth="1170px" my={4}>
            <h1
              style={{
                textAlign: 'center',
                fontSize: '2rem',
                fontWeight: 'bold',
              }}
            >
              All About You
            </h1>
            <p
              style={{
                textAlign: 'center',
                fontSize: '1.25rem',
                marginTop: '0.5rem',
              }}
            >
              Help us know you better and give you the dream experience.
            </p>
            <form className="w-full mt-6" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <InputField label="First Name" id="firstname" placeholder="First name" xs={12} md={6}  value={formData.firstName} onChange={(e) => handleInputChange('firstName', e.target.value)} required />
                <InputField label="Last Name" id="lastname" placeholder="Last name" xs={12} md={6}  value={formData.lastName} onChange={(e) => handleInputChange('lastName', e.target.value)} required />

                <InputField label="Street Address" id="street" placeholder="Enter Street" xs={12} md={6} value={formData.address.street} onChange={(e) => handleInputChange('address', { ...formData.address, street: e.target.value })} required />
                <InputField label="Apartment No" id="apartmentNo" placeholder="Enter Apartment No" xs={12} md={6} value={formData.address.apartmentNo} onChange={(e) => handleInputChange('address', { ...formData.address, apartmentNo: e.target.value })}  />

                <InputField label="City" id="city" placeholder="Enter City" xs={12} md={4} value={formData.address.city} onChange={(e) => handleInputChange('address', { ...formData.address, city: e.target.value })} required />
                <InputField label="State" id="state" placeholder="Enter State" xs={12} md={4} value={formData.address.state} onChange={(e) => handleInputChange('address', { ...formData.address, state: e.target.value })} required />
                <InputField label="Zip Code" id="zipCode" placeholder="Enter Zip Code" xs={12} md={4 } value={formData.address.zipCode} onChange={(e) => handleInputChange('address', { ...formData.address, zipCode: e.target.value })} required />

                <InputField label="Email" id="email" placeholder="Email id" type="email" xs={12} md={6} value={formData.email} onChange={(e) => handleInputChange('email', e.target.value)} required />
                <InputField label="Phone Number" id="number" placeholder="Enter your mobile number" type="tel" pattern="[0-9]{10}" xs={12} md={6} value={formData.phoneNumber} onChange={(e) => handleInputChange('phoneNumber', e.target.value)} required />

                <DateField label="Your Birthday" id="yourbirthday" xs={12} md={6} value={formData.birthday} onChange={(date) => handleInputChange('birthday', date)} required />
                <DateField label="Wedding Anniversary" id="weddinganniversary" placeholder="Enter your Wedding Anniversary" xs={12} md={6} value={formData.weddingAnniversary} onChange={(date) => handleInputChange('weddingAnniversary', date)} />

                <InputField label="Your Favorite Restaurant" id="favoriterestaurant" placeholder="Enter Your Favorite Restaurant" xs={12} md={6} value={formData.favoriterestaurant} onChange={(e) => handleInputChange('favoriterestaurant', e.target.value)} />
                <InputField label="Your Hobby" id="hobby" placeholder="Your Hobby" xs={12} md={6} value={formData.hobby} onChange={(e) => handleInputChange('hobby', e.target.value)} />


                <h3 className="w-full text-center mt-4 text-xl font-bold">Spouse Details</h3>

                <InputField label="Spouse Name" id="spousename" placeholder="Enter your spouse's name" xs={12} md={6} value={formData.spouseName} onChange={(e) => handleInputChange('spouseName', e.target.value)} />                
                <InputField label="Spouse's Email" id="spouseemail" placeholder="Email id" type="email" xs={12} md={6} value={formData.spouseemail} onChange={(e) => handleInputChange('spouseemail', e.target.value)}  />
                <DateField label="Spouse's Birthday" id="spousebirthday" xs={12} md={12} value={formData.spouseBirthday} onChange={(date) => handleInputChange('spouseBirthday', date)} />
                <InputField label="Spouse's Favorite Restaurant" id="favorite" placeholder="Enter favorite restaurant" xs={12} md={6} value={formData.spousesfavoriteRestaurant} onChange={(e) => handleInputChange('spousesfavoriteRestaurant', e.target.value)} />
                <InputField label="Spouse Hobby" id="spousehobby" placeholder="Spouse hobby" xs={12} md={6} value={formData.spouseHobby} onChange={(e) => handleInputChange('spouseHobby', e.target.value)} />


              
                <h3 className="w-full text-center mt-4 text-xl font-bold">Children Details</h3>
                {formData.children.map((child, index) => (
                  <React.Fragment key={index}>
                    <InputField label={`Child ${index + 1} Name`} id={`child${index}name`} placeholder={`Enter ${index + 1} child's name`} xs={12} md={6} value={child.name} onChange={(e) => handleArrayChange('children', index, 'name', e.target.value)} />
                    <DateField label={`Child ${index + 1} Birthday`} id={`child${index}birthday`} xs={12} md={6} value={child.birthday} onChange={(date) => handleArrayChange('children', index, 'birthday', date)}  />
                    <InputField label={`Child ${index + 1} favourite Restaurant`} id={`child ${index+1} favourite restaurant`} placeholder={`Enter ${index + 1} child's favourite restaurant`} xs={12} md={6} value={child.restaurant} onChange={(e) => handleArrayChange('children', index, 'restaurant', e.target.value)}  />
                    <InputField label={`Child ${index + 1} Hobby`} id={`child${index}hobby`} placeholder={`Child ${index + 1} Hobby`} xs={12} md={6} value={child.hobby} onChange={(e) => handleArrayChange('children', index, 'hobby', e.target.value)}  />

                   
                  </React.Fragment>
                ))}
                <AddMoreButton onClick={addChild} label="+ Add Another Child" />

                <h3 className="w-full text-center mt-4 text-xl font-bold">Pets Details</h3>
                {formData.pets.map((pet, index) => (
                  <React.Fragment key={index}>
                    <InputField label={`Pet ${index + 1} Name`} id={`pet${index + 1}name`} placeholder="Enter pet's name" xs={12} md={6} value={pet.name} onChange={(e) => handleArrayChange('pets', index, 'name', e.target.value)} />
                    <InputField label="Species" id={`pet${index + 1}species`} placeholder="Enter pet's species" xs={10} md={5} value={pet.species} onChange={(e) => handleArrayChange('pets', index, 'species', e.target.value)} />
                    <Grid item xs={2} md={1}>
                      <IconButton onClick={() => removeField('pets', index)} color="black">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
                <AddMoreButton onClick={addPet} label="+ Add Another Pet" />

                
                <h3 className="w-full text-center mt-4 text-xl font-bold">List 2 people you think we should meet</h3>
                {formData.contacts.map((contact, index) => (
                  <React.Fragment key={index}>
                    <InputField label={`Name`} id={`contact${index + 1}name`} placeholder="Enter contact's name" xs={12} md={4} value={contact.name} onChange={(e) => handleArrayChange('contacts', index, 'name', e.target.value)} />
                    <InputField label="Phone Number" id={`contact${index + 1}phone`} placeholder="Enter contact's phone number" xs={12} md={3} value={contact.phone} onChange={(e) => handleArrayChange('contacts', index, 'phone', e.target.value)} />
                    <InputField label="Email" id={`contact${index + 1}email`} placeholder="Enter contact's email id" xs={10} md={4} value={contact.email} onChange={(e) => handleArrayChange('contacts', index, 'email', e.target.value)} />


                    <Grid item xs={2} md={1}>
                      <IconButton onClick={() => removeField('contacts', index)} color="black">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    style={{
                      borderRadius: '45px',
                      padding: '10px 0',
                      marginTop: '1rem',
                    }}
                  >
                    Submit
                  </Button>
                  <Typography variant="body2" align="center" color="textSecondary" className="mt-6">
                    *Disclaimer: All the information provided on this form will be kept confidential and will not be shared with any 3rd parties.
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message="Cannot remove, minimum fields required" />
      <Snackbar open={openSnackbar2} autoHideDuration={6000} onClose={() => setOpenSnackbar2(false)} message="Item removed" />
    </LocalizationProvider>
  );
}

function DateField({ label, id, xs, md, value, onChange, required }) {
  return (
    <Grid item xs={xs} md={md}>
      <DatePicker label={label} value={value} onChange={onChange} renderInput={(params) => <TextField {...params} fullWidth required={required} />} />
    </Grid>
  );
}

function InputField({ label, id, placeholder, type = 'text', xs, md, value, onChange, required }) {
  return (
    <Grid item xs={xs} md={md}>
      <TextField label={label} id={id} name={id} placeholder={placeholder} type={type} fullWidth variant="outlined" value={value} onChange={onChange} required={required} />
    </Grid>
  );
}

function TextAreaField({ label, id, placeholder, xs, value, onChange, required }) {
  return (
    <Grid item xs={xs}>
      <TextField label={label} id={id} name={id} placeholder={placeholder} multiline rows={4} fullWidth variant="outlined" value={value} onChange={onChange} required={required} />
    </Grid>
  );
}

function AddMoreButton({ onClick, label }) {
  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        onClick={onClick}
        style={{
          background: 'linear-gradient(145deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1))',
          backdropFilter: 'blur(10px)',
          borderRadius: '40px',
          color: 'black',
          margin: '2px 0',
          border: '1px solid black',
          transition: 'all 0.3s ease',
          padding: '5px 20px',
          fontSize: '1rem',
        }}
        sx={{
          '&:hover': {
            background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2))',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            transform: 'translateY(-2px)',
          },
        }}
        
      >
        {label}
      </Button>
    </Grid>
  );
}

export default AboutYouForm;
